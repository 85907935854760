import React from "react";
import { useInView } from 'react-intersection-observer';
import styled from "styled-components";
import Navbar from "./Navbar";
import { Canvas } from '@react-three/fiber';
import { MeshDistortMaterial, OrbitControls, Sphere } from '@react-three/drei';

const Section = styled.div`
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px){
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px){
    flex: 1;
    align-items: center;
    }
`;

const Right = styled.div`
  height: 500px;
  flex: 1;
  position: relative;

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const Title = styled.h1`
  font-size: 78px;
  opacity: 0;
  filter: blur(5px);
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1),transform 3s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(-15px);

  @media only screen and (max-width: 768px){
    text-align: center;
    }

  ${({ isVisible }) => isVisible && `
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  `}
    
`;

const WhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  opacity: 0;
  filter: blur(5px);
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1),transform 3s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(-15px);

  ${({ isVisible }) => isVisible && `
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  `}
`;

const Line = styled.img`
  height: 5px;
`;

const Subtitle = styled.h2`
  color: #999;
`;

const Description = styled.p`
  font-size: 24px;
  color: lightgray;

  opacity: 0;
  filter: blur(5px);
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1),transform 3s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(-15px);

  @media only screen and (max-width: 768px){
    padding: 20px;
    text-align: center;
    }

  ${({ isVisible }) => isVisible && `
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  `}
`;

const ScrollButton = styled.a`
  width: 200px;
  -webkit-text-size-adjust: 100%;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  display: flex;
  margin-top: 18px;
  padding-left: 0px;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid #7b0084;
  border-left: 2px none #7b0084;
  background-color: transparent;
  transition: all 508ms cubic-bezier(.77, 0, .175, 1);
  font-size: 11px;
  letter-spacing: 4px;
  text-transform: uppercase;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;

  &:hover {
  outline: 0;
  padding-left: 7px;
  background-color: rgba(11, 39, 90, 0.24);
  }
`;

const Img = styled.img`
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  pointer-events: none;
`;

const Dot = styled.span`
  color: #7b0084;
  line-height: 1px;
  opacity: 0;
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1);

  ${({ isVisible }) => isVisible ? `
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  ` : `
    opacity: 0;
  `}
`;

const Hero = () => {
  const { ref: titleRef, inView: isTitleVisible } = useInView({ triggerOnce: true });

  return (
    <Section>
      <Navbar />
      <Container>
        <Left>
          <Title ref={titleRef} isVisible={isTitleVisible}>
            Unlock Your Digital Potential
            <Dot isVisible={isTitleVisible} style={{ transitionDelay: '1s' }}>.</Dot></Title>
          <WhatWeDo isVisible={isTitleVisible}>
            <Line src='./img/line.png' />
            <Subtitle>What we do</Subtitle>
          </WhatWeDo>
          <Description isVisible={isTitleVisible}> our team is ready to work with you to bring your custom software idea to life.</Description>
          <ScrollButton href="#WHO" isVisible={isTitleVisible}>Learn More</ScrollButton>
        </Left>
        <Right>
          <Canvas>
            <OrbitControls enableZoom={false} />
            <ambientLight intensity={1} />
            <directionalLight position={[3, 2, 1]} intensity={1.5} />
            <Sphere args={[1, 100, 200]} scale={2.5}>
              <MeshDistortMaterial
                color="#68006f"
                attach="material"
                distort={0.5}
                speed={0.5}
              />
            </Sphere>
          </Canvas>
          <Img src='./img/laptop.png' />
        </Right>
      </Container>
    </Section>
  );
}

export default Hero;