import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
    display: flex;
    justify-content: center;

    position: fixed;
    top: 0px;
    z-index: 200;
    overflow: hidden;
    width: 100%;
    height: 100px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#000), color-stop(59%, rgba(0, 0, 0, 0.8)), to(transparent));

    @media only screen and (max-width: 768px){
    width: 100%;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    width: min(100ch, 100% - 4rem);
    margin-inline: auto;

    @media only screen and (max-width: 768px){
    width: 100%;
    padding: 10px;
    }
`;

const Links = styled.div`
    display: flex;
    align-items: center;
    gap: 60px;
`;

const List = styled.ul`
  display: flex;
  gap: 20px;
  list-style: none;

  @media only screen and (max-width: 768px){
    display: none;
    }
`;

const ListItem = styled.li`
    cursor: pointer;
`;

const Icons = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const Logo = styled.img`
    height: 80px;
`;

const Button = styled.a`
    width: 95px;
    padding: 10px 20px;
    font-size: 14px;
    background-color: rgb(123, 0, 132);
    border: 1px solid rgb(123, 0, 132);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    transition-duration: 0.4s;
    text-transform: uppercase;

    &:hover {
        outline: 0;
        background-color: rgba(82, 11, 90, 0.24);
    }

`;

const Navbar = () => {
  return (
    <Section>
        <Container>
            <Links>
            <Logo src='./img/logo2.svg'/>
            <List>
                {/* <ListItem>Home</ListItem>
                <ListItem>Studio</ListItem>
                <ListItem>Works</ListItem> */}
            </List>
            </Links>
            <Icons>
                <Button href="#CONTACT">Contact Us</Button>
            </Icons>
        </Container>
    </Section>
  );
}

export default Navbar;