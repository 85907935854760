import React from "react";
import styled from "styled-components";

const Section = styled.div`
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px){
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px){
    margin-top: 50px;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px){
    align-items: center;
    text-align: center;
  }
`;

const TitleWrapper = styled.div`
  -webkit-text-size-adjust: 100%;
  font-size: 1.4em;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  color: #8d2e98;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media only screen and (max-width: 768px){
    font-size: 1.2em;
    text-align: center;
  }
`;

const Subtitle = styled.h1`
    margin-top: 20px;
 @media only screen and (max-width: 768px){
    margin-top: 10px;
    text-align: center;
  }
`;

const Paragraph = styled.p`
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  z-index: 100;
  padding-right: 0px;
  color: #999;
  font-size: 1.3em;
  line-height: 28px;
  font-weight: 300;
  padding-top: 55px;
  text-align: justify;
`;

const Skillset = () => {

  return (
    <Section id="SKILL">
      <Container>
        <Left>
          <div>
            <TitleWrapper>
              <strong class="bold-text">Our Skillset</strong>
            </TitleWrapper>
            <Subtitle>Full Stack Web Development, DevOps &amp; <br />Cybersecurity</Subtitle>
          </div>
        </Left>
        <Right>
          <div>
            <Paragraph>
              With a passion for software development and a deep understanding of business administration, I bring a unique combination of expertise to the table. My educational background includes an MBA in Mobile Software Development and a postgraduate degree in Business Administration.
              <br />My experience has given me a profound knowledge of the business model involved in creating software within cloud services and developing custom software solutions. I understand the importance of keeping these solutions economically viable for the company while ensuring they meet the evolving needs of the market.
              <br />As a highly skilled software developer, I bring technical expertise to the table. This enables me to understand the intricacies of software development processes, guide teams effectively, and ensure high-quality deliverables.
              <br />I look forward to connecting with you. If you have an exciting software development project in mind, let's join hands and bring your ideas to life!
            </Paragraph>
          </div>
        </Right>
      </Container>
    </Section>
  );
}

export default Skillset;