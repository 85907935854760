import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const FooterWrapper = styled.footer`
    padding: 20px 0;
`;

const Container = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

const LinksWrapper = styled.div`
    ul {
        list-style: none;
        padding: 0;
        display: flex;

        li {
        margin-right: 15px;
            a {
                color: #555;
                text-decoration: none;
            }
        }
    }
    
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const SocialWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    ul {
        list-style: none;
        padding: 0;
        display: flex;

        li {
        margin-right: 10px;

        a {
            color: #555;
            text-decoration: none;
        }
        }
    }
`;

const BottomWrapper = styled.div`
    margin-top: 20px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #b9b9b9;

    a {
        color: #555;
        text-decoration: none;
    }
`;

const Footer = () => {
    return (
        <FooterWrapper>
            <Container>
                <LinksWrapper>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#WHO">About Us</a></li>
                        <li><a href="#CUSTOM">Why</a></li>
                        <li><a href="#PROFESSIONAL">Professional</a></li>
                        <li><a href="#CONTACT">Contact</a></li>
                    </ul>
                </LinksWrapper>

                <SocialWrapper>
                    <h3>Follow Us</h3>
                    <ul>
                        <li><a href="https://www.linkedin.com/company/m9-dev-solutions/"><FontAwesomeIcon icon={faLinkedin} style={{ color: '#8d2e98' }} /></a></li>
                    </ul>
                </SocialWrapper>
            </Container>

            <BottomWrapper>
                <p>&copy; 2023 M9 Solution LLC. All rights reserved.</p>
                <p>Designed and developed by <a href="https://www.m9solution.com">M9 Solution</a></p>
            </BottomWrapper>
        </FooterWrapper>
    );
};

export default Footer;