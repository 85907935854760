import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 100px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen and (max-width: 768px){
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  -webkit-text-size-adjust: 100%;
  color: #fff;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 55px;
  margin-top: 10px;
  flex: 1;
  font-size: 3.7em;
`;

const Subtitle = styled.p`
  margin-top: 20px;
  margin-bottom: 10px;
  color: #999;
  font-size: 1.3em;
  line-height: 28px;
  font-weight: 300;
  text-align: justify;
`;

const Dot = styled.span`
  color: #7b0084;
`;

const ItemContainer = styled.div`
  color: #fff;
  position: relative;
  padding-top: 34px;
  padding-right: 11px;
  flex: 1;
`;

const ItemTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  position: relative;
  left: -16px;
  height: 10px;
  margin-top: -5px;
  margin-bottom: 0px;
  padding-top: 1px;
  padding-bottom: 3px;
  padding-left: 17px;
  color: #fff;
  line-height: 20px;
`;

const Number = styled.h1`
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 44px;
  margin-top: 20px;
  position: absolute;
  left: -30px;
  top: -5px;
  color: #1a1919;
  font-size: 80px;
`;

const TopItem = styled.h1`
  position: relative;
  margin-top: 0px;
  padding-top: 35px;
  padding-bottom: 0px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300; 
`;

const Item = styled.h1`
  margin-top: 8px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300; 
`;

const CustomSoftware = () => {

  return (
    <Section id="CUSTOM">
      <Container>
        <TitleWrapper>
          <Title>Why Custom Software <Dot>+</Dot></Title>
          <Subtitle>
            Custom software development is important because it helps meet unique requirements at a cost competitive with purchasing, maintaining and modifying commercial software.
          </Subtitle>
        </TitleWrapper>
        <GridWrapper>
          <ItemContainer>
            <Number>01</Number>
            <ItemTitle>Streamlined Processes</ItemTitle>
            <TopItem>Elimination of redundant tasks</TopItem>
            <Item>Workflow optimization</Item>
            <Item>Automation of manual processes</Item>
            <Item>Task prioritization and scheduling</Item>
          </ItemContainer>
          <ItemContainer>
            <Number>02</Number>
            <ItemTitle>Tailored Functionality</ItemTitle>
            <TopItem>Identifying essential features</TopItem>
            <Item>Customizable modules and components</Item>
            <Item>Personalization options for users</Item>
            <Item>Integration of specific business requirements</Item>
          </ItemContainer>
          <ItemContainer>
            <Number>03</Number>
            <ItemTitle>Integration Capabilities</ItemTitle>
            <TopItem>Seamless integration with existing systems</TopItem>
            <Item>Data synchronization across platforms</Item>
            <Item>API compatibility and connectivity</Item>
            <Item>Real-time data exchange</Item>
          </ItemContainer>
          <ItemContainer>
            <Number>04</Number>
            <ItemTitle>Scalability and Flexibility</ItemTitle>
            <TopItem>Modular architecture for easy expansion</TopItem>
            <Item>Adapting to evolving business needs</Item>
            <Item>Handling increased user load</Item>
            <Item>Supporting multiple branches</Item>
          </ItemContainer>
          <ItemContainer>
            <Number>05</Number>
            <ItemTitle>Reduced Training Time</ItemTitle>
            <TopItem>Intuitive user interface design</TopItem>
            <Item>Simplified workflows and navigation</Item>
            <Item>Onboarding assistance and tutorials</Item>
            <Item>Contextual help and tooltips</Item>
          </ItemContainer>
          <ItemContainer>
            <Number>06</Number>
            <ItemTitle>Time and Cost Savings</ItemTitle>
            <TopItem>Reduction of manual and repetitive tasks</TopItem>
            <Item>Minimization of errors and rework</Item>
            <Item>Lower licensing and subscription costs</Item>
            <Item>Resource optimization and productivity gains</Item>
          </ItemContainer>
        </GridWrapper>
      </Container>
    </Section>
  );
}

export default CustomSoftware;