import React from 'react';
import styled from 'styled-components';


const Section = styled.div`
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  padding-top: 60px;

  @media only screen and (max-width: 768px){
    background: url(./img/portrait2.png) no-repeat center center;
    background-size: contain;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 768px){
    align-items: center;
  }
`;

const Right = styled.div`
 flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const Img = styled.img`
  height: 800px;
  object-fit: contain;
  margin: auto;
  pointer-events: none;
`;

const Title = styled.h1`
 -webkit-text-size-adjust: 100%;
  color: #fff;
  box-sizing: border-box;
  margin: .67em 0;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 90px;
  margin-top: 10px;
  z-index: 100;
  padding-top: 29px;
  padding-bottom: 29px;
  font-size: 6.5em;

  @media only screen and (max-width: 768px){
    line-height: 40px;
    margin-top: 0px;
    padding-top: 0px;
    font-size: 3em;
    padding-bottom: 0px;
    margin-bottom: 5px;
    text-align: center;
  }
`;

const Hello = styled.h2`
  line-height: 1px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 10;
  display: block;
  font-size: 2em;
  font-weight: 500;

  @media only screen and (max-width: 768px){
    margin-top: 280px;
    font-size: 1.4em;
    padding-bottom: 0px;
    text-align: center;
  }
`;

const ScrollButton = styled.a`
  -webkit-text-size-adjust: 100%;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  display: flex;
  margin-top: 18px;
  padding-left: 0px;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid #8d2e98;
  border-left: 2px none #8d2e98;
  background-color: transparent;
  transition: all 508ms cubic-bezier(.77, 0, .175, 1);
  font-size: 11px;
  letter-spacing: 4px;
  text-transform: uppercase;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
  width: 280px;

  &:hover {
  outline: 0;
  padding-left: 7px;
  background-color: rgba(11, 39, 90, 0.24);
  }
`;

const Dot = styled.span`
  font-weight: bold;
  color: #7b0084;
`;

const Subtitle = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  z-index: 100;
  color: #999;
  font-size: 1.3em;
  line-height: 28px;
  font-weight: 300;
  text-align: justify;
  padding-right: 92px;

  @media only screen and (max-width: 768px){
    padding-right: 0;
  }
`;




const Michel = () => {

  return (
    <Section id='PROFESSIONAL'>
      <Container>
        <Left>
          <List>
              <Hello>
                Meet our <Dot>CEO</Dot>,
              </Hello>
              <Title>
                Michel Maia<Dot>.</Dot>
              </Title>

            <Subtitle>
              As a fullstack software developer, I actively contribute to shaping the future of software development by skillfully designing sleek user interfaces that enhance user engagement with valuable information and data. With a strong passion for my work, I constantly seek innovative solutions and strive to create intuitive experiences that empower users to effortlessly navigate complex systems.
            </Subtitle>

            <ScrollButton href="#SKILL">
              SCROLL FOR MORE
            </ScrollButton>
          </List>
        </Left>
        <Right>
          <Img src='./img/portrait2.png' />
        </Right>
      </Container>
    </Section>
  );
}

export default Michel;