import React from "react";
import styled from "styled-components";
import Who from "./components/Who";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import Michel from "./components/Michel";
import Skillset from "./components/Skillset";
import CustomSoftware from "./components/CustomSoftware";
import Footer from "./components/Footer";

function App() {

  const Container = styled.div`
    color: white;
    scroll-behavior: smooth !important;
    width: min(100ch, 100% - 4rem);
    margin-inline: auto;
  `;

  return (
    <Container>
      <Hero />
      <Who />
      <CustomSoftware />
      <Michel />
      <Skillset />
      <Contact />
      <Footer />
    </Container>
  )
}

export default App;
